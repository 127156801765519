import { GeoloniaMap } from '@geolonia/embed-react';
import type geolonia from '@geolonia/embed';
import React, { useCallback, useRef, useState } from 'react';
import './App.css';

type GenImg = {
  ts: string
  processing: true
} | {
  ts: string
  processing: false
  image: string
  layers: { name: string, image?: string }[]
}

const App: React.FC = () => {
  const [generatedImages, setGeneratedImages] = useState<GenImg[]>([]);
  const mapRef = useRef<geolonia.Map>(null);
  const exportMap = useCallback<React.MouseEventHandler<HTMLButtonElement>>(async (event) => {
    event.preventDefault();
    const map = mapRef.current;
    if (!map) return;
    const ts = new Date().toISOString();
    const div = map.getCanvas();
    const b = map.getBounds();
    setGeneratedImages((is) => [{ ts, processing: true }, ...is]);
    const style = map.getStyle();
    const resp = await fetch('https://h7xajmo4ga.execute-api.ap-northeast-1.amazonaws.com/render', {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
      },
      body: JSON.stringify({
        style,
        zoom: map.getZoom(),
        bounds: [b.getWest(), b.getSouth(), b.getEast(), b.getNorth()].join(','),
        bearing: Math.abs(map.getBearing()),
        width: div.clientWidth,
        height: div.clientHeight,
        geoloniaToken: 'd18eae722bd74584a2131fb318c3b312',
        ratio: 2,
      }),
    });

    const imgBlob = await resp.blob();
    const objectUrl = URL.createObjectURL(imgBlob);
    setGeneratedImages((is) => {
      const pos = is.findIndex((x) => x.ts === ts);
      const newIs = [...is];
      newIs[pos] = {
        processing: false,
        ts,
        image: objectUrl,
        layers: (style.layers || []).map(ml => ({
          name: ml.id,
        })),
      };
      return newIs;
    });

    for (const ml of (style.layers || [])) {
      const resp = await fetch('https://h7xajmo4ga.execute-api.ap-northeast-1.amazonaws.com/render', {
        method: 'POST',
        headers: {
          'content-type': 'application/json',
        },
        body: JSON.stringify({
          style: {
            ...style,
            layers: [
              ml,
            ],
          },
          zoom: map.getZoom(),
          bounds: [b.getWest(), b.getSouth(), b.getEast(), b.getNorth()].join(','),
          bearing: Math.abs(map.getBearing()),
          width: div.clientWidth,
          height: div.clientHeight,
          geoloniaToken: 'd18eae722bd74584a2131fb318c3b312',
          ratio: 2,
        }),
      });

      const imgBlob = await resp.blob();
      const objectUrl = URL.createObjectURL(imgBlob);

      setGeneratedImages((is) => {
        const pos = is.findIndex((x) => x.ts === ts);

        const imgData = {
          ...is[pos],
        };
        if (imgData.processing === true) return is;
        const lPos = imgData.layers.findIndex((x) => x.name === ml.id);
        imgData.layers[lPos] = { name: ml.id, image: objectUrl };
        const newIs = [...is];
        newIs[pos] = imgData;
        return newIs;
      });
    }
  }, [mapRef]);

  return (
    <div className="container">
      <div className='my-2'>
        <button
          type="button"
          className='btn btn-outline-primary'
          onClick={exportMap}
        >
          書き出し
        </button>
      </div>
      <div className="map-outer">
        <div className="map-inner">
          <GeoloniaMap
            mapRef={mapRef}
            apiKey='d18eae722bd74584a2131fb318c3b312'
            style={{ width: '100%', height: '100%' }}
            lang='ja'
            // mapStyle='geolonia/gsi'
            mapStyle='https://keichan34.github.io/otenki/docs/style.json'
          />
        </div>
      </div>
      <div>
        { generatedImages.map(img => <div key={img.ts} className='my-2'>
          { img.processing ? <>
            <h3>{img.ts}</h3>
            処理中・・・
          </> : <>
            <h3>{img.ts}</h3>
            <a href={img.image} download={img.ts}>保存</a><br />
            <img className='img-fluid' src={img.image} alt={img.ts} />
            <div className=''>
              <table className='table'>
                <tbody>
                  { img.layers.map((l) => <tr key={l.name}>
                    <td>{l.name}</td>
                    <td>
                      { l.image && <img className='img-fluid transparentbg' src={l.image} alt={l.name} /> }
                    </td>
                  </tr>) }
                </tbody>
              </table>
            </div>
          </> }
        </div>) }
      </div>
    </div>
  );
}

export default App;
